var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"lmind-table mt-0"},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.readAll}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa fa-book")]),_vm._v("全部标注已读 ")],1)],1),_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.dataSource,"item-key":"id","options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"footer-props":{'items-per-page-options': [5,10,20,50]}},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.rowIndex",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.msgCategory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.msgCategoryText(item.msgCategory)))])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.priorityText(item.priority)))])]}},{key:"item.readFlag",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.readFlagText(item.readFlag)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.showAnnouncement(item)}}},[_vm._v(" 查看 ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }